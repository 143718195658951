import { gsap } from "gsap";

// Navigation
export function initNav() {
  const navOpenButton = document.querySelector(".nav-open");
  const navCloseButton = document.querySelector(".nav-close");
  const cursor = document.querySelector(".ball");
  const body = document.querySelector("body");

  const tl = gsap.timeline({
    paused: "true",
  });
  tl.to(".slide-nav.menu", {
    duration: 0.75,
    x: "0%",
    ease: "expo.easeOut",
  });
  tl.fromTo(
    ".slide-nav .ul .li",
    {
      y: "-100%",
      opacity: 0,
    },
    {
      duration: 0.6,
      opacity: 1,
      y: "0%",
      stagger: 0.2,
    }
  );
  tl.fromTo(
    ".slide-nav .social .social-li",
    {
      y: "-50%",
      opacity: 0,
    },
    {
      duration: 0.45,
      opacity: 1,
      stagger: 0.15,
      ease: "expo.easeInOut",
    },
    "-=0.1"
  );
  tl.fromTo(
    ".slide-nav .supporters__list li",
    {
      opacity: 0,
    },
    {
      duration: 1,
      opacity: 1,
      stagger: 0.4,
      ease: "expo.easeInOut",
    },
    "-=0.1"
  );

  function cursorDarkBG(cursor) {
    cursor.style.setProperty("--cursor-stroke", "#f0e3d3");
  }
  function cursorLightBg(cursor) {
    cursor.style.setProperty("--cursor-stroke", "#191919");
  }

  function toggleNavOpen() {
    tl.play();
    body.classList.add("slide-nav-open");
  }
  function toggleNavClose() {
    tl.reverse();
    body.classList.remove("slide-nav-open");
  }

  navOpenButton.addEventListener("click", () => {
    toggleNavOpen();
    cursorDarkBG(cursor);
  });
  navCloseButton.addEventListener("click", () => {
    toggleNavClose();
    cursorLightBg(cursor);
  });
}
